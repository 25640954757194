<template>
  <b-card>
    <h5>Editar Perfil</h5>
    <form-render class="mt-1" :form.sync="form" :fields="fields" @send="updateProfile" ref="formRenderPassword" :buttonSend="buttonSend" containerButtonsClass="col-sm-12 mt-1">
      <template #buttons>
        <b-button variant="outline-light" class="ml-2" @click="resetForm"><feather-icon icon="RefreshCwIcon"/> {{$t('Limpiar formulario')}}</b-button>
      </template>
      <template #space>
      </template>
    </form-render>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { BButton, BCard } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { DateTime } from 'luxon'

export default {
  components: {
    BButton,
    BCard
  },
  directives: {
    Ripple
  },
  data() {
    return {
      avatar: null,
      profileFile: null,
      buttonSend: { icon: 'SaveIcon', text: 'Guardar cambios', color: 'warning'},
      form: {},
      fields: [
        { name: 'skeleton1', useSkeleton: true, containerClass: 'col-sm-12 col-md-6 container-info' },
        { name: 'skeleton2', useSkeleton: true, containerClass: 'col-sm-12 col-md-6 container-info hidden' },
        { name: 'skeleton3', useSkeleton: true, containerClass: 'col-sm-12 col-md-6 container-info' },
        { name: 'skeleton4', useSkeleton: true, containerClass: 'col-sm-12 col-md-6 container-info' }//,
      ],
      defaultTimezone: DateTime.local().setZone('system').zoneName,
      defaultTypeFormat: 'dd-mm-yyyy',
      loading: {
        form: true,
        image: true,
        uploading: false
      }
    }
  },
  computed: {
    ...mapGetters({
      userData: 'getUserData'
    })
  },
  watch: {
    profileFile () {
      if (this.profileFile) {
        if (this.profileFile.size <= 1000000) {
          this.uploadToStorage()
        } else {
          this.avatar = this.form.avatar
          this.$alert(this.$t('oversize-image-avatar'))
        }
      }
    },
    userData () {
      if (this.userData?.id) this.setInitialData()
    },
    form: {
      handler (curr, prev) {
        this.buttonSend = { icon: 'SaveIcon', text: 'Guardar cambios', color: 'warning'}
      },
      deep: true
    }
  },
  mounted () {
    if (this.userData?.id) this.setInitialData()

  },
  methods: {
    setInitialData () {
      this.setDataProfile()
      this.fields = [
        { fieldType: 'FieldInput', type: 'email', label: 'E-mail', name: 'email', containerClass: 'col-sm-6 container-info', disabled: true, validation: 'email' },
        { name: 'space', useSlot: true, containerClass: 'col-sm-12 col-md-6 container-info' },
        { fieldType: 'FieldInput', type: 'text', label: 'Nombre', name: 'first_name', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', clearable: false },
        { fieldType: 'FieldInput', type: 'text', label: 'Apellido', name: 'last_name', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', clearable: false } //,
      ]
      this.buttonSend = { icon: 'SaveIcon', text: 'Guardar cambios', color: 'warning'}
    },
    async updateProfile (data) {
      this.buttonSend.icon = 'LoaderIcon'
      this.buttonSend.iconClass = 'spinner'
      this.buttonSend.disabled = true
      const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        fullName: `${data.first_name} ${data.last_name}`
      }
    },
    setDataProfile () {
      this.form = {...this.userData}
      this.avatar = this.form.avatar
      this.loading.form = false
    },
    resetForm() {
      this.form = {}
      this.setDataProfile()
    },
    uploadToStorage () {
      this.loading.uploading = true
      const storageRef = this.$root.$data.fb.storage().ref(this.$session.get('cas_user').id).put(this.profileFile)
      storageRef.on('state_changed', snapshot => {
        this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      }, error => {
        this.loading.uploading = false
      },
      () => {
        this.uploadValue = 100
        storageRef.snapshot.ref.getDownloadURL().then((url) => {
          this.avatar = url
          this.updatePictureUser(this.$session.get('cas_user').id, url)
        })
      })
    },
    updatePictureUser (id, url) {
      this.loading.uploading = true
      this.$root.$data.accountsCollection.doc(id).update({avatar: url})
        .then(resp => {
          this.$store.dispatch('fetchItemUserData', { element: 'avatar', value: url})
          this.loading.uploading = false
        })
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer
    }
  }
}
</script>
<style lang="scss">
.overimage{
  position: absolute;
  z-index: 1;
  left: 40px;
  top: 40px;
}
.container-avatar{
  width: 80px;
}
</style>
